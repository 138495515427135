import { message } from 'antd';

const { createAsyncThunk } = require('@reduxjs/toolkit');
const { axiosInstance } = require('api/axios');

export const createAccount = createAsyncThunk(
  'installer/createAccount',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/installers', {
        ...payload,
        role: 'installer',
      });
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.error?.message);
    }
  }
);

export const installerLogin = createAsyncThunk(
  'installer/login',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/installers/login', payload);
      localStorage.setItem('token', response.token);
      localStorage.setItem('installerId', response.installer._id);
      localStorage.setItem('user_id', response.installer._id);
      localStorage.setItem('user_role', response.installer.role);
      return response.token;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const installerForgetPassword = createAsyncThunk(
  'installer/forgetPassword',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/installers/forget-password',
        payload
      );
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const installerVerifyOtp = createAsyncThunk(
  'installer/verifyOtp',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/installers/verify-otp',
        payload
      );
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const installerResetPassword = createAsyncThunk(
  'installer/resetPassword',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/installers/reset-password',
        payload
      );
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const installerLogout = createAsyncThunk(
  'installer/logout',
  async (_, { rejectWithValue }) => {
    try {
      const role = localStorage.getItem('user_role');
      localStorage.clear();
      window.location.href =
        role === 'installer' ? '/login' : '/customer/login';
      return true;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const customerLogin = createAsyncThunk(
  'customer/login',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/customer/login', {
        email: payload.email,
        password: payload.password,
      });
      localStorage.setItem('customer_name', response?.customer?.name || 'User');
      localStorage.setItem('customer_company_name', response?.customer?.companyName || '')
      localStorage.setItem('user_id', response?.customer?._id);
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const verifyToken = createAsyncThunk(
  'users/verifyToken',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get('/verify-token');
      if (response.message === 'Invalid token') {
        return false;
      }
      if (response.message === 'Token is valid') {
        return true;
      }
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);
