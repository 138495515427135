import React, { useEffect, useState } from "react";
import { SideBar } from "./SideBar";
import { INSTALLER_ROUTER } from "router/installerRouter";
import { NavBar } from "./NavBar";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStartedProjects, getCompletedProjects } from "store/bid/bidActions";
import { Flag, Message, CheckBox } from "assets/svgs/icons";
import { PageLoader } from "components/elements";
import { Badge } from "antd";

function Installer() {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("user_role");

  const dispatch = useDispatch();
  const { startedProjects, completedProjects } = useSelector(
    (state) => state.bid
  );
  const { messageCount } = useSelector((state) => state.chat);
  const { loading } = useSelector((state) => state.auth);
  const installerId = localStorage.getItem("installerId");
  const [projectRoutes, setProjectRoutes] = useState(INSTALLER_ROUTER);

  useEffect(() => {
    dispatch(getStartedProjects());
    dispatch(getCompletedProjects());
  }, [dispatch, installerId]);


  useEffect(() => {
    let startedChildren = startedProjects?.map((site) => ({
      key: site?._id,
      label: site?.siteId?.nameOfSite,
      path: `/dashboard/projects/${site._id}`,
    }));
    const startedProjectRouteObj = {
      key: "4",
      icon: <Flag />,
      label: "Projects Started",
      children: [...startedChildren],
    };

    let completedChildren = completedProjects?.map((site) => ({
      key: site?._id,
      label: site?.siteId?.nameOfSite,
      path: `/dashboard/project-completed/${site._id}`,
    }));
    const completedProjectRouteObj = {
      key: "5",
      icon: <CheckBox />,
      label: "Projects Completed",
      children: [...completedChildren],
    };

    const messageObj = {
      key: "6",
      icon: (
        <Badge
          color="#8DC63F"
          dot={messageCount > 0 && true}
          count={messageCount}
          className="relative"
        >
          <Message />
        </Badge>
      ),
      label: "Messages",
      path: "/dashboard/messages",
    };

    const routerArray = [...INSTALLER_ROUTER];
    routerArray.splice(3, 0, startedProjectRouteObj, completedProjectRouteObj);
    routerArray.splice(5, 0, messageObj);

    setProjectRoutes(routerArray);
  }, [startedProjects, completedProjects, messageCount]);

  if (loading) {
    return <PageLoader />;
  }

  if (token && userRole === "installer") {
    return (
      <div className="relative flex">
        <div className="flex flex-col">
          <SideBar routes={projectRoutes} />
        </div>
        <div className="p-2 md:px-10 md:py-5 flex flex-col w-full">
          <NavBar />
          <div className="mt-0 bg-[white]">
            <Outlet />
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/login" />;
  }
}

export { Installer };
