import React from "react";
import moment from "moment";
import CalendarIcon from "assets/svgs/Calendar.svg";
import WatchIcon from "assets/svgs/Watch.svg";
const SiteTime = ({ title, date, time }) => {
  return (
    <div className="w-full  space-y-2">
      <span className="text-sm md:text-base text-gray3 font-[400]">
        {title}
      </span>
      <div className="flex flex-col space-y-2">
        <span className="inline-flex gap-2 items-center">
          <span>
            <img
              src={CalendarIcon}
              alt="calendar icon"
              className="h-[15px] w-[15px] ml-[1px]"
            />
          </span>
          <p className="text-sm md:text-base  text-[#5E6278] font-[500] capitalize">
            {moment(date).format("MMMM Do, YYYY")}
          </p>
        </span>
        <span className="inline-flex gap-2 items-center">
          <span>
            <img
              src={WatchIcon}
              alt="calendar icon"
              className="h-[20px] w-[18px]"
            />
          </span>
          <p className="text-sm md:text-base  text-[#5E6278] font-[500] capitalize">
            {time}
          </p>
        </span>
      </div>
    </div>
  );
};

export default SiteTime;
