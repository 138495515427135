import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createInvite,
  getInvitesForCustomer,
  updateInvitationStatus,
} from 'store/invites/invitesAction';
import { getRecommendedInstallersBySiteId } from 'store/sites/sitesActions';
import { toast } from 'react-toastify';
import { Button } from 'components/elements';

//Images
import DuckImage from 'assets/images/Duck.png';
import GlobeIcon from 'assets/svgs/Globe.svg';
import PersonIcon from 'assets/svgs/ProfileCircle.svg';
import { useNavigate } from 'react-router-dom';
import ContactInfoPopup from './ContactDetailPopup';

const InstallerInviteCard = ({
  type,
  installer,
  siteId,
  customerId,
  loading,
  technologies,
  bid,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const onClickSendInvite = () => {
    const payload = {
      siteId,
      customerId,
      installerId: installer?._id,
      bidRequestedBy: "customer",
      status: "accepted",
    };

    dispatch(createInvite(payload))
      .unwrap()
      .then((res) => {
        toast.success("Invite sent successfully!", { toastId: "invite" });
        dispatch(getRecommendedInstallersBySiteId(siteId));
      })
      .catch((err) => {
        toast.error(err || "Something went wrong!", { toastId: "inviteError" });
      });
  };

  const onClickInvitation = (status) => {
    const payload = {
      id: bid,
      data: {
        status,
      },
    };
    dispatch(updateInvitationStatus(payload))
      .unwrap()
      .then((res) => {
        toast.success("Invite updated successfully!", {
          toastId: "inviteUpdate",
        });
        dispatch(
          getInvitesForCustomer({
            siteId,
            customerId,
          })
        );
      })
      .catch((err) => {});
  };

  const handleViewProfile = () => {
    navigate(`/installer-profile/${installer._id}`);
  };

  return (
    <>
      <div className='w-[340px] flex-column justify-between gap-8 p-2 md:p-4 lg:p-6 rounded-md shadow-md'>
        <div>
          <div className='row-flex items-center gap-2'>
            <div className='w-10 h-10 bg-white rounded-full shadow-[0px_0px_10px_0px_#00000024] p-2 flex items-center justify-center '>
              <img src={installer?.profileImage || DuckImage} alt='icon' />
            </div>
            <div className='flex-column'>
              <p className='text-base md:text-lg font-[400]'>
                {installer?.companyName || 'Doe Limited'}
              </p>
              <p
                onClick={handleViewProfile}
                className='text-xs text-primary cursor-pointer'
              >
                View Profile
              </p>
            </div>
          </div>

          <div className='flex-column mt-5 gap-4'>
            <InfoRow
              icon={PersonIcon}
              title='Representative Name'
              desc={installer?.name || 'John Doe'}
            />
            <InfoRow
              icon={GlobeIcon}
              title='Website Name'
              desc={installer?.website || 'johndoe.co.uk'}
            />
          </div>
          <div className='mt-4'>
            <p className='text-[10px] md:text-xs text-[#98A2B3]'>
              Technologies
            </p>
            <div className='flex flex-wrap gap-x-4  gap-y-2'>
              {technologies?.length > 0
                ? technologies?.map((item) => (
                  <div className='row-flex items-center gap-1' key={item._id}>
                    <p className='text-xs  text-[#5E6278] font-[500] capitalize'>
                      {item?.technologyType === 'gas chp'
                        ? 'Gas CHP'
                        : item?.technologyType === 'biomass chp'
                          ? 'Biomass CHP'
                          : item?.technologyType}
                    </p>
                    <p
                      className={`text-xs  ${item.isMatch
                        ? 'bg-secondary text-primary'
                        : 'bg-lightGray text-white'
                        } rounded-full py-1 px-1 font-[500]`}
                    >
                      {item?.capacity?.toFixed(1)} kW
                    </p>
                  </div>
                ))
                : []}
            </div>
          </div>
        </div>
        <div className='w-full'>
          {type === 'sendInvite' ? (
            <Button
              loading={loading}
              text='Send Invite'
              variant='bordered'
              onClick={onClickSendInvite}
              className='w-full'
            />
          ) : (
            <div className='row-flex items-center gap-4'>
              <Button
                loading={loading}
                text='Reject'
                variant='bordered'
                className='!border-[red] !text-[red] w-full'
                onClick={() => onClickInvitation('rejected')}
              />
              <Button
                loading={loading}
                text='Accept'
                variant='bordered'
                className='w-full'
                onClick={() => onClickInvitation('accepted')}
              />
            </div>
          )}
        </div>
      </div>

    </>
  );
};

const InfoRow = ({ icon, title, desc }) => (
  <div className='flex-column gap-1'>
    <p className='text-[10px] md:text-xs text-[#98A2B3]'>{title}</p>
    <div className='row-flex items-center gap-2'>
      <img src={icon} alt='icon' />
      <p className='text-sm md:text-base text-[#5E6278] font-[500] break-all'>
        {desc}
      </p>
    </div>
  </div>
);

export { InstallerInviteCard };
