import { COST_INFO } from "mock/data";
import React from "react";

function BidDetails({ title, bidInfo, className }) {
  if (
    !bidInfo ||
    typeof bidInfo !== "object" ||
    Object.keys(bidInfo).length === 0
  ) {
    return null;
  }

  return (
    <div className={`${className}`}>
      <span className="text-sm md:text-base  text-gray3">{title}</span>
      <div className="flex flex-wrap gap-4">
        {Object.keys(bidInfo)
          .slice(0, 4)
          .map((item) => (
            <div key={item} className="flex flex-col items-center gap-0">
              <p className="text-sm md:text-base text-[#5E6278] font-[500]">
                {COST_INFO[item] || "N/A"}
              </p>
              <p className="text-lg text-primary py-1 px-2 font-[500]">
                £ {bidInfo[item] ?? "0.00"}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
}

export { BidDetails };
