import React, { useState, useEffect } from "react";
import { Button, Checkbox, Textarea } from "components/elements";
import { TECHNOLOGIES, REGIONS } from "mock";
import { Input, PasswordInput } from "components/elements";
import { useForm } from "react-hook-form";
import { Step1Schema, Step2Schema } from "validations";
import rightIcon from "assets/svgs/ArrowRightWhite.svg";
import ArrowLeftGreen from "assets/svgs/ArrowLeftGreen.svg";
import { yupResolver } from "@hookform/resolvers/yup";

import { Alert } from "antd";
import {
  ANIMATE_SLIDEIN_DOWN,
  ANIMATE_SLIDEIN_RIGHT,
  ANIMATE_SLIDEIN_UP,
} from "utils/animations";
import { useDispatch, useSelector } from "react-redux";
import { createAccount } from "store/auth/authActions";
import { toast } from "react-toastify";

const Step1 = ({ handleNext, setFormState, formState }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: formState?.name || "",
      email: formState?.email || "",
      password: formState?.password || "",
    },
    resolver: yupResolver(Step1Schema),
  });

  const handleFormSubmit = (data) => {
    const { name, email, password } = data;
    setFormState((prevState) => ({
      ...prevState,
      name: name,
      email: email,
      password: password,
    }));
    handleNext();
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex-column gap-4">
        <Input
          name="name"
          label="Representative Name"
          variant="white"
          control={control}
          className="w-full"
          error={errors && errors?.name?.message}
        />
        <Input
          name="email"
          label="Email Address"
          variant="white"
          control={control}
          error={errors && errors?.email?.message}
        />
        <PasswordInput
          name="password"
          label="Password"
          variant="white"
          control={control}
          setValue={setValue}
          error={errors && errors?.password?.message}
          className={`${ANIMATE_SLIDEIN_DOWN}`}
        />
        <div className="gap-4 flex-column justify-end sm:row-flex mt-7`">
          <Button
            text="Next"
            type="submit"
            // rightIcon={rightIcon}
            // onClick={handleNext}
            className={ANIMATE_SLIDEIN_DOWN}
          />
        </div>
      </div>
    </form>
  );
};

const Step2 = ({
  handleNext,
  handlePrevious,
  setFormState,
  formState: setForm,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      companyName: setForm?.companyName || "",
      address: setForm?.address || "",
      postcode: setForm?.postcode || "",
      website: setForm?.website || "",
      description: setForm?.description || "",
    },
    resolver: yupResolver(Step2Schema),
  });

  const onChange = (name, value) => {
    setFormState({ ...setForm, [name]: value });
  };
  const handleFormSubmit = (data) => {
    setFormState((prevState) => ({
      ...prevState,
      ...data,
    }));
    handleNext();
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex-column gap-4">
        <Input
          name="companyName"
          label="Company Name"
          variant="white"
          error={errors && errors?.companyName?.message}
          control={control}
          onChange={(e) => {
            onChange("companyName", e.target.value);
          }}
        />
        <Input
          name="address"
          label="Address"
          variant="white"
          error={errors && errors?.address?.message}
          control={control}
          onChange={(e) => {
            onChange("address", e.target.value);
          }}
        />
        <Input
          name="postcode"
          label="Postcode"
          variant="white"
          error={errors && errors?.postcode?.message}
          control={control}
          onChange={(e) => {
            onChange("postcode", e.target.value);
          }}
        />
        <Input
          name="website"
          label="Website"
          variant="white"
          error={errors && errors?.website?.message}
          control={control}
          divClassName={ANIMATE_SLIDEIN_DOWN}
          onChange={(e) => {
            onChange("postcode", e.target.value);
          }}
        />
        <Textarea
          name="description"
          label="Description"
          variant="white"
          rows={3}
          error={errors && errors?.description?.message}
          control={control}
          divClassName={ANIMATE_SLIDEIN_DOWN}
          onChange={(e) => {
            onChange("description", e.target.value);
          }}
        />
        <div className="gap-4 flex-column justify-between sm:row-flex mt-7`">
          <Button
            text="Previous"
            variant="secondary"
            leftIcon={ArrowLeftGreen}
            onClick={handlePrevious}
            className={ANIMATE_SLIDEIN_DOWN}
          />
          <Button
            text="Next"
            rightIcon={rightIcon}
            type="submit"
            className={ANIMATE_SLIDEIN_DOWN}
          />
        </div>
      </div>
    </form>
  );
};

const Step3 = ({
  handleNext,
  handlePrevious,
  setFormState,
  step,
  formState: stateForm,
}) => {
  const { control, handleSubmit, setValue, unregister } = useForm({
    mode: "onChange",
    defaultValues: {
      ...stateForm?.technologies?.reduce(
        (acc, { technologyType, minCapacity, maxCapacity }) => {
          acc[`min - ${technologyType}`] = minCapacity?.toString();
          acc[`max - ${technologyType}`] = maxCapacity?.toString();
          return acc;
        },
        {}
      ),
    },
  });

  const [error, setError] = useState(false);
  const [techs, setTechs] = useState(TECHNOLOGIES);

  useEffect(() => {
    const updatedTechs = techs.map((tech) => {
      const matchingTech = stateForm?.technologies?.find(
        (t) => t.technologyType === tech.name
      );
      if (matchingTech) {
        return {
          ...tech,
          displayed: true,
          checked: true,
        };
      } else {
        return tech;
      }
    });
    setTechs(updatedTechs);
  }, [stateForm?.technologies]);

  const onChange = (key, displayed, name, checked) => {
    let map = techs.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          displayed: !displayed,
        };
      }
      return item;
    });

    if (!checked) {
      unregister(`max - ${name}`, "");
      unregister(`min - ${name}`, "");
    }
    setTechs(map);
  };

  const onSubmit = (d) => {
    let data = Object.entries(d)
      .filter(([key, value]) => key.startsWith("min - "))
      .map(([minKey, minValue]) => {
        const heatName = minKey?.substring("min - ".length);
        const maxKey = `max - ${heatName}`;
        const maxValue = d[maxKey];
        return {
          technologyType: heatName,
          minCapacity: minValue,
          maxCapacity: maxValue,
        };
      })
      .filter((entry) => entry !== null);

    if (
      data.some(
        (entry) =>
          !entry.technologyType ||
          entry.minCapacity === undefined ||
          entry.minCapacity === null ||
          entry.minCapacity === "" ||
          entry.maxCapacity === undefined ||
          entry.maxCapacity === null ||
          entry.maxCapacity === ""
      )
    ) {
      setError(
        "Please select minimum and maximum values of selected technologies"
      );
    } else if (data.length === 0) {
      setError("Select at least one technology");
    } else {
      setError("");
      setFormState((prevState) => ({
        ...prevState,
        technologies: data,
      }));
      handleNext();
    }
  };

  return (
    <form
      className="h-full flex-column justify-center gap-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={`flex-column gap-4`}>
        <p className={`text-darkGray ${ANIMATE_SLIDEIN_UP}`}>
          Tick all technologies that apply.
        </p>
        <p className={`font-semibold text-darkBlue ${ANIMATE_SLIDEIN_UP}`}>
          Technologies
        </p>
        {error ? (
          <div className="flex items-center w-full">
            <Alert message={error} type="error" showIcon />
          </div>
        ) : null}
        <div className={`grid md:grid-cols-2 antialiased gap-[24px]`}>
          <div className="pl-1 space-y-5">
            {techs
              .slice(0, 5)
              .map(({ title, name, displayed, key, checked }, index) => (
                <div className="flex-column gap-4" key={index}>
                  <Checkbox
                    name={name}
                    title={title}
                    control={control}
                    checked={displayed && checked}
                    onChange={(e) => {
                      onChange(key, displayed, name, e.target.checked);
                    }}
                  />
                  {displayed ? (
                    <div>
                      <Input
                        label="Min. Project Capacity (KW)"
                        name={`min - ${name}`}
                        variant="white"
                        control={control}
                        labelType="normal"
                        className="1420:h-[50px]"
                        divClassName={`w-[80%] ${ANIMATE_SLIDEIN_DOWN}`}
                      />
                      <Input
                        label="Max. Project Capacity (KW)"
                        name={`max - ${name}`}
                        variant="white"
                        control={control}
                        labelType="normal"
                        className="1420:h-[50px]"
                        divClassName={`w-[80%] ${ANIMATE_SLIDEIN_DOWN}`}
                      />
                    </div>
                  ) : null}
                </div>
              ))}
          </div>
          <div className={`space-y-5 ${ANIMATE_SLIDEIN_RIGHT}`}>
            {techs
              .slice(5)
              .map(({ title, name, displayed, checked, key }, index) => (
                <div className="flex-column gap-4" key={index}>
                  <Checkbox
                    name={name}
                    title={title}
                    control={control}
                    checked={checked && displayed}
                    onChange={(e) => {
                      onChange(key, displayed, name, e.target.checked);
                    }}
                  />
                  {displayed ? (
                    <div>
                      <Input
                        label="Min. Project Capacity (KW)"
                        name={`min - ${name}`}
                        variant="white"
                        control={control}
                        labelType="normal"
                        className="1420:h-[50px]"
                        divClassName={`w-[80%] ${ANIMATE_SLIDEIN_DOWN}`}
                      />
                      <Input
                        label="Max. Project Capacity (KW)"
                        name={`max - ${name}`}
                        variant="white"
                        control={control}
                        labelType="normal"
                        className="1420:h-[50px]"
                        divClassName={`w-[80%] ${ANIMATE_SLIDEIN_DOWN}`}
                      />
                    </div>
                  ) : null}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className={`gap-4 flex-column justify-between sm:row-flex mt-7`}>
        <Button
          variant="secondary"
          text="Previous"
          leftIcon={ArrowLeftGreen}
          type="submit"
          onClick={handlePrevious}
          className={ANIMATE_SLIDEIN_DOWN}
        />

        <Button
          text="Next"
          rightIcon={rightIcon}
          // onClick={handleNext}
          className={ANIMATE_SLIDEIN_DOWN}
        />
      </div>
    </form>
  );
};

const Step4 = ({ handlePrevious, formState, setFormState }) => {
  const [error, setError] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { control, handleSubmit, setValue, reset, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      region: formState.region || [],
    },
  });

  const handleCheckboxChange = (checked, value) => {
    const regions = getValues("region") || [];
    if (checked) {
      setValue("region", [...regions, value]);
    } else {
      setValue(
        "region",
        regions.filter((region) => region !== value)
      );
    }
  };

  const onSubmit = (data) => {
    const { region } = data;
    if (region.length > 0) {
      setError(false);
      const data = {
        ...formState,
        region: region,
      };
      dispatch(createAccount(data))
        .unwrap()
        .then(() => {
          reset();
          toast.success("Your account is created successfully!", {
            toastId: "account",
          });
          setTimeout(() => {
            window.location.href = "/login";
          }, 2000);
        })
        .catch((err) => {
          setError(err);
        });
    } else {
      setError("Please select one field");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-column gap-4 flex-column  md:max-h-[300px] pl-1 md:overflow-y-auto overflow-x-hidden">
        <p className={`font-semibold text-darkBlue ${ANIMATE_SLIDEIN_UP}`}>
          Regions
        </p>
        {error ? (
          <div className="flex items-center w-full">
            <Alert message={error} type="error" showIcon />
          </div>
        ) : null}
        <div className="grid grid-cols-2 gap-5 ">
          {REGIONS.map(({ title, name }, index) => {
            return (
              <Checkbox
                name={name}
                title={title}
                key={index}
                onChange={(e) => {
                  handleCheckboxChange(e.target.checked, name);
                }}
                control={control}
                className={index % 2 !== 0 ? ANIMATE_SLIDEIN_RIGHT : ""}
              />
            );
          })}
        </div>
      </div>
      <div className={`gap-4 flex-column justify-between sm:row-flex mt-7`}>
        <Button
          variant="secondary"
          text="Previous"
          leftIcon={ArrowLeftGreen}
          onClick={handlePrevious}
          className={ANIMATE_SLIDEIN_DOWN}
        />

        <Button
          text="Finish"
          type="submit"
          loading={loading}
          rightIcon={rightIcon}
          className={ANIMATE_SLIDEIN_DOWN}
        />
      </div>
    </form>
  );
};

const CreateAccountForm = ({ step, handlePrevious, handleNext }) => {
  const [formState, setFormState] = useState({});
  switch (step) {
    case 1:
      return (
        <Step1
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          setFormState={setFormState}
          formState={formState}
        />
      );
    case 2:
      return (
        <Step2
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          setFormState={setFormState}
          formState={formState}
        />
      );
    case 3:
      return (
        <Step3
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          setFormState={setFormState}
          formState={formState}
          step={step}
        />
      );
    case 4:
      return (
        <Step4
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          setFormState={setFormState}
          formState={formState}
        />
      );
    default:
      return null;
  }
};

export { CreateAccountForm };
