import { axiosInstance } from 'api/axios';
const { createAsyncThunk } = require('@reduxjs/toolkit');

export const createSite = createAsyncThunk(
  '/site/create',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/sites', payload);
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getSites = createAsyncThunk(
  '/site/getSites',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/sites/technologies');
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getSitesByCustomerId = createAsyncThunk(
  '/site/getSitesByCustomerId',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/sites/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getCustomerByIdShortListed = createAsyncThunk(
  '/site/getSitesByCustomerId/shortListed',
  async ({ id, isShortListed = false }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/sites/${id}?isShortListed=${isShortListed}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getBidsBySiteId = createAsyncThunk(
  '/site/getBidsBySiteId',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bids/site/${id}`);
      return response.bids;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getRecommendedInstallersBySiteId = createAsyncThunk(
  '/site/getRecommendedInstallersBySiteId',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/sites/${id}/recommended-installers`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const sendInviteToInstaller = createAsyncThunk(
  '/site/sendInviteToInstaller',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/bid/request`, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getBidRequestsInCustomer = createAsyncThunk(
  '/site/getBidRequestsInCustomer',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bids/site/status/${id}`);
      return response.bids;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);



export const addAttachmentsToSite = createAsyncThunk(
  '/site/attachments',
  async ({ id, attachments }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/sites`, { attachments, siteId: id });
      return response.bids;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);
