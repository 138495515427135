import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const Map = ({ center }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAoRBJLtxC5BLYQo9nvj1Epbj812rSHKj8',
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      center={center}
      zoom={10}
      mapContainerStyle={{ width: '100px', height: '100px', borderRadius: '20px' }}
    >
      <Marker position={center} />
    </GoogleMap>
  );
};

export default Map;
