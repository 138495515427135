import { PageLoader } from 'components/elements';
import { BidCard } from 'components/installer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSites } from 'store/sites/sitesActions';

const Opportunities = () => {
  const dispatch = useDispatch();
  const { loading, sites } = useSelector((state) => state.sites);

  useEffect(() => {
    dispatch(getSites());
  }, [dispatch]);

  return loading ? (
    <div className="w-full h-[90vh] m-auto flex items-center justify-center">
      <PageLoader />
    </div>
  ) : sites.length === 0 ? (
    <p>No sites Found!</p>
  ) : (
    <div className="flex-column gap-8 w-full h-full py-4 md:py-8 mb-4 px-1">
      {sites.map((site, i) => (
        <BidCard
          customer={site?.customerId}
          type="opportunity"
          site={site}
          key={i}
        />
      ))}
    </div>
  );
};

export { Opportunities };
