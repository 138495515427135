import React from "react";
import DocumentIcon from "assets/svgs/Document.svg";
import Map from "components/global/GoogleMap";
import LocationIcon from "assets/svgs/Location.svg";
import { BID_TYPES } from "mock";

function BidHeader({
  siteName,
  customerName,
  latitude,
  longitude,
  type,
  addressOfSite,
}) {
  return (
    <>
      <div className="row-flex items-start justify-between gap-4 md:gap-8">
        <p
          className={`w-fit flex-none text-xs font-[600] rounded-[6px] p-[8px] capitalize ${
            type ? BID_TYPES[type].styles : "text-green bg-lightGreen"
          }`}
        >
          {BID_TYPES[type].name}
        </p>
        <div className="flex items-end flex-col gap-2">
          <div className="row-flex gap-1">
            <img
              src={LocationIcon}
              alt="location"
              className="w-[0.9rem] h-[1rem] md:w-[1.1rem] md:h-[1.2rem]"
            />
            <p className="text-xs md:text-sm text-gray">
              {addressOfSite
                ? addressOfSite
                : "123 Apartment, Floor 1 ABC 123 123 Apartment."}
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between items-center gap-2 mt-4">
        <div className="flex flex-col gap-1">
          <div className="flex gap-2">
            <div className="h-[48px] w-[48px] rounded-full flex items-center justify-center bg-[#F9FFF1]">
              <img src={DocumentIcon} alt="doc" className="w-[26px] h-[28px]" />
            </div>
            <div className="flex flex-col">
              <p className="text-black text-base lg:text-lg capitalize font-[500]">
                {siteName}
              </p>
              <p className="text-darkGray2 text-sm lg:text-base capitalize font-[400]">
                {customerName}
              </p>
            </div>
          </div>
        </div>
        <Map center={{ lat: latitude, lng: longitude }} />
      </div>
    </>
  );
}

export default BidHeader;
