import React, { useMemo } from "react";
import { Tooltip } from "antd";
import { convertCamelToNormal, toCamelCase } from "utils";
import InfoIcon from "assets/svgs/Info.svg";

// Helper function to check if a technology has been updated
const checkIfUpdated = (oldTechnologies, tech) => {
  const normalizedType = toCamelCase(tech?.technologyType || "").toLowerCase();
  const matchingOldTech = oldTechnologies.find(
    (oldTech) =>
      toCamelCase(oldTech?.technologyType || "").toLowerCase() ===
      normalizedType
  );
  return matchingOldTech && matchingOldTech.capacity !== tech.capacity;
};

export const TechnologyList = ({
  technologies: newTechnologies = [],
  oldTechnologies = [],
}) => {
  // Memoized processing of technologies
  const processedTechnologies = useMemo(() => {
    if (!newTechnologies || newTechnologies.length === 0)
      return oldTechnologies;
    return newTechnologies.map((tech) => ({
      ...tech,
      isUpdated: checkIfUpdated(oldTechnologies, tech),
    }));
  }, [newTechnologies, oldTechnologies]);

  return (
    <div className="my-2">
      <div className="inline-flex gap-2 items-center">
        <p className="text-[#98A2B3] my-1 text-sm">
          Technology Type & Capacities
        </p>
      </div>
      <div className="grid-container">
        {processedTechnologies.length > 0 ? (
          processedTechnologies.map(
            ({ technologyType, capacity, isUpdated }, index) => (
              <div
                key={index}
                className="flex flex-col xl:flex-row xl:items-center gap-2"
              >
                <p className="text-sm md:text-base text-[#5E6278] font-[500] capitalize">
                  {convertCamelToNormal(technologyType || "Unknown")}
                </p>
                <div className="flex items-center gap-1">
                  <p
                    className={`text-[10px] md:text-xs 
                     text-[#749816] bg-[#F4FFD7] rounded-full py-1 w-fit sm:max-w-[150px] px-2 font-[500]`}
                  >
                    {Math.round((capacity || 0) * 10) / 10 || "N/A"} kW{" "}
                  </p>
                  {isUpdated && (
                    <Tooltip title="This capacity has been updated by the installer.">
                      <img src={InfoIcon} alt="Info Icon" className="h-4 w-4" />
                    </Tooltip>
                  )}
                </div>
              </div>
            )
          )
        ) : (
          <p className="text-sm md:text-base text-[#5E6278] font-[500]">
            No technologies available
          </p>
        )}
      </div>
    </div>
  );
};
