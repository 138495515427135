import { Button, Checkbox, Input, Modal } from 'components/elements'
import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateBid } from "store/bid/bidActions";
import { getSitesByCustomerId } from "store/sites/sitesActions";
import { updateBidById } from "store/bid/bidSlice";

// Define the validation schema
const contactInfoSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup.string().required("Phone number is required"),
  consent: yup.boolean().oneOf([true], ""),
});

export default function ContactInfoPopup({ open, bid, handleClose }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(contactInfoSchema),
    defaultValues: {
      email: "",
      phone: "",
      consent: false,
    },
  });

  const dispatch = useDispatch();

  // Watch form field values
  const watchEmail = watch("email");
  const watchphone = watch("phone");
  const watchConsent = watch("consent");

  const isSubmitDisabled = !watchEmail || !watchphone || !watchConsent;

  const onSubmitHandler = (data) => {
    const payload = {
      id: bid?._id,
      data: {
        customerInfo: {
          ...data,
        },
      },
    };
    dispatch(updateBid(payload))
      .unwrap()
      .then((res) => {
        toast.success("Contact information added!", {
          toastId: "bidUpdate",
        });

        dispatch(updateBidById({ ...bid, customerInfo: data }));
        handleClose();
      })
      .catch((err) => {
        toast.error(err || "Something went wrong!", {
          toastId: "bidUpdateError",
        });
      });
  };

  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ["gb"].includes(iso2);
  });

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      className="max-w-lg mx-auto p-6"
    >
      <div className="space-y-6 text-center">
        <h2 className="text-2xl font-semibold">Contact Information</h2>
        <p className="text-gray-600">
          Please provide your contact details so the installer can contact you
          directly regarding your renewable project. We will only use your
          information for this purpose.
        </p>

        <form onSubmit={handleSubmit(onSubmitHandler)} className="space-y-4">
          <div className="space-y-2">
            <Input
              id="email"
              type="text"
              name="email"
              placeholder="Enter your email"
              control={control}
              error={errors.email?.message}
            />
          </div>
          <div>
            <PhoneInput
              defaultCountry="gb"
              disableCountryGuess
              addInternationalOption={false}
              countries={countries}
              preferredCountries={["gb"]}
              inputStyle={{
                width: "100%",
                border: "none",
                backgroundColor: "#F5F7FB",
                height: "50px",
              }}
              style={{
                border: "none",
                width: "100%",
                height: "50px",
              }}
              value={watchphone}
              onChange={(phone) => setValue("phone", phone)}
              inputProps={{
                name: "phone",
                autoFocus: false,
              }}
            />
            <p className="text-xs xl:text-sm text-red-500 -mt-1">
              {errors.phone?.message}
            </p>
          </div>

          <div className="flex items-start ">
            <Checkbox
              name="consent"
              control={control}
              error={errors.consent?.message}
              onChange={(e) => setValue("consent", e.target.checked)}
            />
            <label htmlFor="consent" className="text-sm text-left">
              I consent to my contact details being passed to this installer.
            </label>
          </div>

          <Button
            type="submit"
            text="Submit"
            className="w-full"
            disabled={isSubmitDisabled}
          >
            Submit Contact Information
          </Button>
        </form>
      </div>
    </Modal>
  );
}
