import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/elements";
import { InitialBidPopup } from "./InitialBidPopup";
import { createBidSchema } from "validations";
import {
  createInvite,
  getAcceptedInvitesForInstaller,
} from "store/invites/invitesAction";
import { getSites } from "store/sites/sitesActions";
import { createBid } from "store/bid/bidActions";
import { toast } from "react-toastify";

// Images
import LocationIcon from "assets/svgs/Location.svg";
import DocumentIcon from "assets/svgs/Document.svg";
import DocumentEdit from "assets/svgs/DocumentEdit.svg";
import Download from "assets/svgs/Download.svg";
import FileLists from "components/macros/FileLists";
import Map from "components/global/GoogleMap";
import CompanyName from "components/global/CompanyName";
import { BidDetails } from "./BidDetails";
import { TechnologiesList } from "components/macros";
import BidInformation from "./card";
import FileAttachment from "components/global/FileAttachments";

const BidCard = ({
  type,
  site,
  customer,
  finalBid,
  status,
  technologies,
  bid,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.invite);
  const { loading: bidLoading } = useSelector((state) => state.bid);
  const installerId = localStorage.getItem("installerId");
  const [initialBidPopupOpen, setInitialBidPopupOpen] = useState(false);
  const [finalTechnologies, setFinalTechnologies] = useState([]);

  const showFileList =
    window.location.pathname === "/dashboard/opportunities" ? false : true;

  const handleInitialBidPopupClose = () => {
    setInitialBidPopupOpen(false);
  };

  useEffect(() => {
    if (status === "completed" && technologies.length > 0) {
      setFinalTechnologies(technologies || []);
    } else {
      setFinalTechnologies(site?.technologies || []);
    }
  }, [site, technologies]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createBidSchema),
  });

  // submit bid request
  const onBidSubmit = (data) => {
    const initialBidDetails = {
      installerId: installerId,
      customerId: site?.customerId,
      siteId: site._id,
      bidRequestedBy: "installer",
      status: "pending",
    };
    dispatch(createInvite(initialBidDetails))
      .unwrap()
      .then((res) => {
        toast.success("Your bid request has been submitted successfully!", {
          toastId: "bidRequest",
        });
        dispatch(getSites());
      })
      .catch((err) => {
        toast.error(err, { toastId: "bidRequestError" });
      });
  };

  // submit initial bid
  const onInitialBidSubmit = (data) => {
    const { materialCost, laborCost, additionalCost } = data;
    const initialBidDetails = {
      installerId: installerId,
      customerId: site?.customerId,
      siteId: site._id,
      status: "processing",
      cost: {
        initialBid: {
          materialCost: Number(materialCost),
          laborCost: Number(laborCost),
          additionalCost: Number(additionalCost),
          totalCost:
            Number(materialCost) + Number(laborCost) + Number(additionalCost),
        },
      },
    };

    dispatch(createBid(initialBidDetails))
      .unwrap()
      .then((res) => {
        toast.success(
          "Your initial bid has been submitted. The customer will review the bid and you will receive a notification about the outcome!",
          {
            toastId: "initialBid",
          }
        );
        setInitialBidPopupOpen(false);
        dispatch(getAcceptedInvitesForInstaller(installerId));
        reset();
      })
      .catch((err) => {
        toast.error(err, { toastId: "initialBidError" });
      });
  };

  return (
    <div className="max-w-[840px] bg-white flex-column gap-4 px-2 md:px-4 xl:px-6 py-4 md:py-6 xl:py-8 rounded-xl shadow-md">
      <BidInformation
        site={site}
        bid={bid}
        type={type}
        cost={bid?.cost}
        customer={customer}
      />
      {site?.attachments?.length > 0 && showFileList && (
        <FileAttachment title="Attachments" attachments={site?.attachments} />
      )}
      {type === "projectStarted" && finalBid && (
        <div>
          {finalBid && finalBid?.attachments?.length > 0 && (
            <FileAttachment
              title="Final Attachments"
              attachments={finalBid?.attachments}
            />
          )}
        </div>
      )}
      {site?.isAlreadyInvited && type === "opportunity" ? (
        <span className="text-primary italic font-semibold ml-auto">
          Requested
        </span>
      ) : type === "opportunity" ? (
        <Button
          type="submit"
          variant="bordered"
          text="Request to Bid"
          className="ml-auto"
          loading={loading}
          onClick={onBidSubmit}
        />
      ) : type === "invitation" ? (
        <Button
          variant="bordered"
          text="Submit Initial Bid"
          className="ml-auto"
          onClick={() => setInitialBidPopupOpen(true)}
        />
      ) : null}
      <InitialBidPopup
        open={initialBidPopupOpen}
        handleClose={handleInitialBidPopupClose}
        control={control}
        onSubmit={onInitialBidSubmit}
        handleSubmit={handleSubmit}
        loading={bidLoading}
        errors={errors}
        watch={watch}
      />
    </div>
  );
};

export { BidCard };
