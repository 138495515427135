import React from "react";

const CompanyName = ({ companyName }) => {
  const role = localStorage.getItem("user_role");

  if (role !== "installer" || !companyName) return null;


  return (
    <div className="flex-column mb-2 gap-1">
      <p className="text-base text-[#98A2B3] font-[400]">Organisation Name</p>
      <p className="text-darkGray2 text-sm lg:text-base capitalize font-[400]">
        {companyName}
      </p>
    </div>
  );
};

export default CompanyName;
