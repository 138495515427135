import React from "react";
import SiteTime from "components/global/SiteTime";
import { TechnologiesList } from "components/macros";
import { BidDetails } from "./BidDetails";
import CalendarIcon from "assets/svgs/Calendar.svg";
import WatchIcon from "assets/svgs/Watch.svg";
import Phone from "assets/svgs/Phone.svg";
import BidHeader from "./BidHeader";
import CompanyName from "components/global/CompanyName";
import { Message } from "assets/svgs/icons";
import { TechnologyList } from "components/macros/Technologies";

const TIME = {
  morning: "08:00am - 12:00pm",
  evening: "01:00pm - 05:00pm",
};

function CustomerInfo({ email, phone }) {
  if (!email && !phone) return null;

  return (
    <div className="flex flex-col gap-2 justify-between">
      <p className="text-sm md:text-base text-gray3 mt-1">Customer Info</p>
      <div className="flex gap-2 flex-col">
        {email && (
          <div className="inline-flex items-center gap-2">
            <Message width={16} color="#8DC63F" height={17} />
            <p className="text-sm md:text-base text-[#5E6278] font-[500]">
              {email}
            </p>
          </div>
        )}
        {phone && (
          <div className="inline-flex items-center gap-2">
            <img src={Phone} />
            <p className="text-sm md:text-base text-[#5E6278] font-[500]">
              {phone}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

function SiteVisitDetails({ visitOne, visitTwo }) {
  if (!visitOne && !visitTwo) return null;

  return (
    <div className="flex gap-4 py-3">
      {visitOne && (
        <SiteTime
          time={visitOne.time}
          date={visitOne.date}
          title="Site Visit One"
        />
      )}
      {visitTwo && (
        <SiteTime
          time={visitTwo.time}
          date={visitTwo.date}
          title="Site Visit Two"
        />
      )}
    </div>
  );
}

function ConfirmedVisitDetails({ date, time }) {
  if (!date || !time) return null;

  return (
    <div className="flex flex-wrap gap-4 md:gap-8 xl:gap-12">
      <div className="flex-column gap-2">
        <p className="text-sm md:text-base text-gray font-[400]">
          Selected Site Visit Time
        </p>
        <div className="flex-column gap-2">
          <p className="row-flex items-center gap-2 text-sm md:text-base font-[500] text-[#5E6278]">
            <span>
              <img
                src={CalendarIcon}
                alt="calendar icon"
                className="h-[15px] w-[15px] ml-[1px]"
              />
            </span>
            {new Date(date)?.toDateString()}
          </p>
          <p className="row-flex items-center gap-2 text-sm md:text-base font-[500] text-[#5E6278] capitalize">
            <span>
              <img
                src={WatchIcon}
                alt="watch icon"
                className="h-[20px] w-[18px]"
              />
            </span>
            {time} ({TIME[time]})
          </p>
        </div>
      </div>
    </div>
  );
}

function BidInformation({ site, type, cost, bid, customer }) {
  const { initialBid, finalBid } = cost || {};
  const { siteVisitOne, siteVisitTwo, confirmedSiteVisitTime, customerInfo } =
    bid || {};
  const { email, phone } = customerInfo || {};
  const { date, time } = confirmedSiteVisitTime || {};

  if (!site) return;

  return (
    <>
      <BidHeader
        siteName={site?.nameOfSite}
        customerName={customer?.name}
        latitude={site?.latitude}
        longitude={site?.longitude}
        addressOfSite={site?.addressOfSite}
        type={type}
      />
      <CompanyName companyName={customer?.companyName} />
      <div className="flex gap-4 flex-col">
        <TechnologyList
          oldTechnologies={site?.technologies}
          technologies={bid?.technologies}
        />
        {initialBid && (
          <BidDetails bidInfo={initialBid} title="Initial Bid Details" />
        )}
      </div>
      {type !== "opportunity" && type !== "invitation" && (
        <>
          <CustomerInfo email={email} phone={phone} />
          <SiteVisitDetails visitOne={siteVisitOne} visitTwo={siteVisitTwo} />
          <ConfirmedVisitDetails date={date} time={time} />

          <BidDetails
            title="Final Bid Details"
            className="mt-2"
            bidInfo={finalBid}
          />
        </>
      )}
    </>
  );
}

export default BidInformation;
